/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { ICheckboxStyleProps, ICheckboxStyles } from '@fluentui/react';

export const CheckboxStyles = (props: ICheckboxStyleProps): Partial<ICheckboxStyles> => {
  const { disabled, checked, theme } = props;
  const { semanticColors } = theme;

  const checkboxBackgroundChecked = semanticColors.inputBackgroundChecked;
  const checkboxBorderColor = semanticColors.smallInputBorder;
  const checkboxBorderColorChecked = semanticColors.inputBackgroundChecked;
  const checkboxBorderHoveredColor = semanticColors.inputBackgroundCheckedHovered;
  const checkboxIconHoveredColor = semanticColors.inputBorder;

  return {
    input: [
      {
        margin: 0,
      },
    ],
    label: [
      !disabled &&
        !checked && {
          selectors: {
            ':hover .ms-Checkbox-checkbox': {
              borderColor: checkboxBorderHoveredColor,
            },

            ':hover .ms-Checkbox-checkmark': {
              color: checkboxIconHoveredColor,
            },

            ':focus .ms-Checkbox-checkbox': { borderColor: checkboxBorderHoveredColor },
          },
        },
    ],
    checkbox: [
      {
        borderColor: checkboxBorderColor,
        borderRadius: 1,
        height: 14,
        width: 14,
      },
      !disabled &&
        checked && {
          background: checkboxBackgroundChecked,
          borderColor: checkboxBorderColorChecked,
        },
    ],

    text: [{ fontSize: 12, lineHeight: 16, marginLeft: 4, marginRight: 0 }],
  };
};
