/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { ITheme } from '@fluentui/theme';
import { ActionButtonStyles } from '../styles/ActionButton.styles';
import { BreadcrumbStyles } from '../styles/Breadcrumb.styles';
import { CheckboxStyles } from '../styles/Checkbox.styles';
import { ComboBoxStyles } from '../styles/ComboBox.styles';
import { CommandBarButtonStyles } from '../styles/CommandBarButton.styles';
import { ContextualMenuStyles } from '../styles/ContextualMenu.styles';
import { DatePickerStyles } from '../styles/DatePicker.styles';
import { DefaultButtonStyles } from '../styles/DefaultButton.styles';
import { DetailsListStyles } from '../styles/DetailsList.styles';
import { DialogStyles } from '../styles/Dialog.styles';
import { DialogContentStyles } from '../styles/DialogContent.styles';
import { DropdownStyles } from '../styles/Dropdown.styles';
import { IconButtonStyles } from '../styles/IconButton.styles';
import { LabelStyles } from '../styles/Label.styles';
import { LinkStyles } from '../styles/Link.styles';
import { NavStyles } from '../styles/Nav.styles';
import { OverlayStyles } from '../styles/Overlay.styles';
import { PivotStyles } from '../styles/Pivot.styles';
import { PrimaryButtonStyles } from '../styles/PrimaryButton.styles';
import { ProgressIndicatorStyles } from '../styles/ProgressIndicator.styles';
import { SearchBoxStyles } from '../styles/SearchBox.styles';
import { TagItemStyles } from '../styles/TagItem.styles';
import { TagPickerStyles } from '../styles/TagPicker.styles';
import { TextFieldStyles } from '../styles/TextField.styles';
import { TooltipStyles } from '../styles/Tooltip.styles';

export const AOneUnityStyleSettings = (theme: ITheme): any => {
  return {
    ActionButton: {
      styles: ActionButtonStyles(theme),
    },
    Breadcrumb: {
      styles: BreadcrumbStyles,
    },
    Checkbox: {
      styles: CheckboxStyles,
    },
    ComboBox: {
      styles: ComboBoxStyles(theme),
    },
    CommandBarButton: {
      styles: CommandBarButtonStyles(theme),
    },
    ContextualMenu: {
      styles: ContextualMenuStyles(theme),
    },
    DatePicker: {
      styles: DatePickerStyles,
    },
    DefaultButton: {
      styles: DefaultButtonStyles(theme),
    },
    DetailsList: {
      styles: DetailsListStyles,
    },
    Dialog: {
      styles: DialogStyles,
    },
    DialogContent: {
      styles: DialogContentStyles,
    },
    Dropdown: {
      styles: DropdownStyles,
    },
    IconButton: {
      styles: IconButtonStyles(theme),
    },
    Label: {
      styles: LabelStyles,
    },
    Link: {
      styles: LinkStyles,
    },
    Nav: {
      styles: NavStyles,
    },
    Overlay: {
      styles: OverlayStyles,
    },
    Pivot: {
      styles: PivotStyles,
    },
    PrimaryButton: {
      styles: PrimaryButtonStyles(theme),
    },
    ProgressIndicator: {
      styles: ProgressIndicatorStyles,
    },
    SearchBox: {
      styles: SearchBoxStyles,
    },
    TagItem: {
      styles: TagItemStyles,
    },
    TagPicker: {
      styles: TagPickerStyles,
    },
    TextField: {
      styles: TextFieldStyles,
    },
    Tooltip: {
      styles: TooltipStyles,
    },
  };
};
