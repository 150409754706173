/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IDropdownStyles, IDropdownStyleProps } from '@fluentui/react';
import * as StyleConstants from '../Constants';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const DropdownStyles = (props: IDropdownStyleProps): Partial<IDropdownStyles> => {
  const { disabled, theme } = props;

  if (!theme) {
    return {};
  }
  const { semanticColors } = theme;

  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { inputBorderFocused, inputBorder } = extendedSemanticColors;

  const dropdownItemStyle = {
    fontSize: 12,
    lineHeight: 16,
    padding: '5px 7px 3px',
    minHeight: 24,
    height: 24,
  };

  return {
    title: [
      {
        borderColor: inputBorder,
        fontSize: 12,
        height: StyleConstants.dropdownHeight,
        lineHeight: StyleConstants.dropdownHeight - 2,
        padding: `1px 18px 1px 6px`,
        borderRadius: 2,
      },
      disabled && {
        backgroundColor: semanticColors.disabledBackground,
      },
    ],
    caretDownWrapper: [
      {
        height: 16,
        lineHeight: StyleConstants.dropdownHeight,
        right: 4,
        width: 16,
      },
    ],
    dropdownItem: [dropdownItemStyle],
    dropdownItemSelected: [dropdownItemStyle],

    dropdown: [
      {
        selectors: {
          ':focus::after, :focus': {
            borderColor: inputBorderFocused,
            borderWidth: 1,
          },
          '.ms-Button-icon': {
            color: 'red',
          },
        },
      },
      disabled && {
        borderWidth: '0px',
      },
      !disabled && {},
    ],
  };
};
