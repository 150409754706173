/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import * as React from 'react';
import { ThemeProvider } from '@fluentui/react';
import { AOneUnityThemeLight } from './theme/AOneUnityThemeLight';
import { initIcons } from './icons';

initIcons();

const DEFAULT_STYLE = { height: '100%' };

export interface AOneUnityThemeProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const AOneUnityTheme = ({ children, style, className }: AOneUnityThemeProps) => {
  const providerStyle = style || DEFAULT_STYLE;
  return (
    <ThemeProvider theme={AOneUnityThemeLight} style={providerStyle} className={className}>
      {children}
    </ThemeProvider>
  );
};

AOneUnityTheme.displayName = 'AOneUnityTheme';
