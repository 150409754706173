/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { IDialogStyleProps, IDialogStyles } from '@fluentui/react/';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const DialogStyles = (props: IDialogStyleProps): Partial<IDialogStyles> => {
  const { theme } = props;
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;
  const { dialogText } = extendedSemanticColors;

  return {
    main: [
      {
        boxShadow: '0 1px 6px 0 rgba(0,0,0,0.25)',
        color: dialogText,
        fontSize: 12,
        minHeight: 0,
        borderRadius: 0,
      },
    ],
  };
};
