/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { ISearchBoxStyleProps, ISearchBoxStyles } from '@fluentui/react';
import { fieldStyles } from './TextField.styles';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const SearchBoxStyles = (props: ISearchBoxStyleProps): Partial<ISearchBoxStyles> => {
  const { disabled, hasFocus, hasInput, theme } = props;
  const { semanticColors } = theme;

  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;
  const { errorText, inputBackgroundSelected, searchBorderFocused } = extendedSemanticColors;

  return {
    root: [
      {
        height: 24,
        fontSize: 11,
        selectors: {
          '::after': {
            borderColor: searchBorderFocused,
            borderWidth: 1,
            inset: 0,
          },
        },
      },

      hasFocus && {
        border: 'none',
      },

      !hasFocus &&
        hasInput && {
          selectors: {
            [`:hover .ms-SearchBox-iconContainer`]: {
              width: 20,
            },
            [`:hover .ms-SearchBox-icon`]: {
              opacity: 1,
            },
          },
        },
    ],
    iconContainer: [
      {
        width: 20,
      },
      hasFocus && {
        width: 0,
      },
    ],
    icon: [
      {
        color: '#000000',
        width: 20,
        height: 20,
      },
      hasFocus && {
        width: 0,
      },
    ],
    field: [
      ...fieldStyles(disabled, inputBackgroundSelected, errorText),
      {
        paddingLeft: 0,
        paddingRight: 0,
      },
    ],

    clearButton: {
      selectors: {
        '.ms-Button': {
          selectors: {
            ':hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  };
};
