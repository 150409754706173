/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IButtonStyles, ITheme } from '@fluentui/react';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const CommandBarButtonStyles = (theme: ITheme): Partial<IButtonStyles> => {
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { commandBarMenuIconDisabled, commandBarMenuHoverBackgroundColor, commandBarMenuIcon, iconBackground } = extendedSemanticColors;

  return {
    root: {
      border: 'none',
      backgroundColor: iconBackground,
      color: commandBarMenuIcon,
      '.ms-Button-icon': {
        color: commandBarMenuIcon,
      },
      '.ms-Button-menuIcon': {
        color: commandBarMenuIcon,
      },
    },

    rootHovered: {
      backgroundColor: commandBarMenuHoverBackgroundColor,
      color: commandBarMenuIcon,
      opacity: 0.8,
      '.ms-Button-icon': {
        color: commandBarMenuIcon,
      },
      '.ms-Button-menuIcon': {
        color: commandBarMenuIcon,
      },
    },

    rootDisabled: {
      backgroundColor: iconBackground,
      color: commandBarMenuIconDisabled,
      '.ms-Button-icon': {
        color: commandBarMenuIconDisabled,
      },
      '.ms-Button-menuIcon': {
        color: commandBarMenuIconDisabled,
      },
    },
  };
};
