/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { ITheme } from '@fluentui/react';
import { IContextualMenuStyles, IContextualMenuItemStyles } from '@fluentui/react/lib/ContextualMenu';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const ContextualMenuStyles = (theme: ITheme): Partial<IContextualMenuStyles> => {
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { contextualMenuBorder, contextualMenuColor, contextualMenuIconColor, contextualMenuDisabledColor } = extendedSemanticColors;

  return {
    subComponentStyles: {
      callout: {
        root: {
          boxShadow: 'rgb(0 0 0 / 40%) 0px 0px 5px 0px',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: contextualMenuBorder,
          '.ms-Callout-main': {
            overflow: 'hidden',
          },

          '.ms-Button': {
            verticalAlign: 'top',
            margin: 0,
          },
        },
        container: [
          'ms-ContextualMenu-container',
          {
            color: contextualMenuColor,
          },
        ],
      },
      menuItem: ({ disabled }): Partial<IContextualMenuItemStyles> => {
        return {
          root: [
            {
              height: 32,
              lineHeight: 32,
              fontSize: 14,
              '.ms-ContextualMenu-icon': {
                maxHeight: 32,
                width: 14,
                fontSize: 14,
              },
              '.ms-ContextualMenu-linkContent': {
                color: disabled ? contextualMenuDisabledColor : contextualMenuColor,
              },
            },
          ],
          icon: {
            color: disabled ? contextualMenuDisabledColor : contextualMenuIconColor,
          },
        };
      },
    },
  };
};
